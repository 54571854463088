<template>
  <div class="app-container">
    <div class="page-title">
      <h2>Le Controleur</h2>
    </div>

    <!-- <el-card class="box-card"> -->
    <div class="filter-container">
      <!-- <el-button style="margin-left: auto" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="PrintProductor">
        Imprimer
      </el-button> -->
    </div>
    <el-row>
      <el-col :span="7" style="margin-right:7px">
        <div class="grid-content bg-purple">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight:bold">Informations personnelles</span>
              <!-- <el-button style="float: right; padding: 3px 0" type="text">Bouton</el-button> -->
            </div>
            <div class="text item" style="display: flex; align-items: center; flex-direction:column">
              <div class="pan-thumb" />
              <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">{{ data.nom + ' ' + data.prenom }}</span>
              <span style="font-weight: bold; font-style: italic; margin-bottom: 5px">Grade: {{ data.grade.nom }}</span>
              <span style="font-weight: italic; font-style: italic; margin-bottom: 5px">{{ data.email }}</span>
              <span style="font-style: italic;">{{ data.sexe ==='f'?'Féminin':'Masculin' | uppercaseFirst }}</span>
              <img :src="data.signature" alt="" width="60px;">
              <!-- <el-table v-loading="loading" :data="persinfos" :show-header="false">
                  <el-table-column>
                    <template slot-scope="scope" style="background-color: bisque">
                      <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column>
                    <template slot-scope="scope">
                      <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                    </template>
                  </el-table-column>
                </el-table> -->
            </div>
          </el-card>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid-content bg-purple-light">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span style="font-weight: bold;">Autres informations</span>
            </div>
            <el-tabs tab-position="top">
              <!-- <el-tab-pane label="Organisation">
                  <el-table v-loading="loading" :data="organInfos" :show-header="false">
                    <el-table-column>
                      <template slot-scope="scope" style="background-color: bisque">
                        <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <span style="font-style: italic;">{{ scope.row.valeur }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane> -->
              <el-tab-pane label="Groupements">
                <el-table :data="data.groupements" border fit highlight-current-row style="width: 100%">

                  <el-table-column align="center" label="Nom" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nom }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Unité de production" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ unites_production[scope.row.unite_production_id] }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Description" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.description }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateGroupement(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Formations conduits">
                <el-table :data="data.conduct_trainings" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Theme" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.theme }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Formateur" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.formateur }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Status" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.status }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="1==1 && $t('general.actions')" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateFormation(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Formations suivies">
                <el-table :data="data.followed_trainings" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Theme" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.theme }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Formateur" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.formateur }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="Status" min-width="150px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.status }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" :label="1==1 && $t('general.actions')" width="200">
                    <template slot-scope="scope">
                      <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateFormation(scope.row.id)" />
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="Audits">
                <el-empty v-if="is_audit === 0" description="Aucun audit" />
                <el-tabs v-if="is_audit === 1" tab-position="left">
                  <el-tab-pane v-for="audit in audits" :key="audit.code" :label="audit.code">
                    <!-- <el-tag type="info" style="margin:10px">
                      Code : {{ audit.code }}
                    </el-tag> -->
                    <el-tag v-if="audit.date_audit != ''" type="info" style="margin:10px">
                      Date : {{ audit.date_audit }}
                    </el-tag>
                    <el-tag v-if="audit.date_audit == ''" type="warning" style="margin:10px">
                      En cours
                    </el-tag>
                    <el-tag v-if="audit.date_audit !== ''" type="success" style="margin:10px">
                      Terminé
                    </el-tag>
                    <el-button v-if="audit.date_audit !== '' && !audit.visa_rf" type="success" icon="el-icon-check" @click="validerAudit(audit.id)">Valider l'audit</el-button>
                    <el-button v-if="audit.date_audit !== '' && audit.visa_rf" type="info" icon="el-icon-download" @click="generateAuditPdf(audit.id)">Télécharger Audit PDF</el-button>

                    <el-collapse v-model="activeName" accordion>
                      <el-collapse-item v-for="item in audit.categories" :key="item.libelle" :title="item.libelle">
                        <el-tag v-if="item.all_questions_valide" type="success" style="margin:10px">
                          Toutes les questions sont validées
                        </el-tag>
                        <el-tag v-if="!item.all_questions_valide" type="warning" style="margin:10px">
                          Les questions ne sont pas toutes validées
                        </el-tag>
                        <el-table v-loading="loading" :data="item.questions" :show-header="true">
                          <el-table-column label="Question">
                            <template slot-scope="scope">
                              <span style="font-weight: bold;">{{ scope.row.libelle }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column label="Réponse">
                            <template slot-scope="scope">
                              <span style="font-style: italic;">{{ scope.row.reponse ? scope.row.reponse : 'AUCUNE' }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column label="Commentaire">
                            <template slot-scope="scope">
                              <span style="font-style: italic;">{{ scope.row.commentaire ? scope.row.commentaire : 'NO COMMENT' }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </el-collapse-item>
                    </el-collapse>
                  </el-tab-pane>
                </el-tabs>
              </el-tab-pane>

              <el-tab-pane label="Fiches de collecte">
                <el-empty v-if="listFicheCollecte.length === 0" description="Aucune Fiches de collecte" />
                <el-table :data="listFicheCollecte" border fit highlight-current-row style="width: 100%">
                  <el-table-column align="center" label="Code Lot" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.code }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Libellé" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.libelle }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="Date création" min-width="200px">
                    <template slot-scope="scope">
                      <span>{{ scope.row.created_at }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" :label="1==1 && $t('general.actions')" width="200">
                    <template slot-scope="scope">
                      <el-link :href="scope.row.lien" type="success" target="_blank">
                        Voir <i class="el-icon-view el-icon--right" />
                      </el-link>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <el-tabs v-if="listFicheCollecte.length > 0" tab-position="left">
                  <el-tab-pane v-for="ficheCollecte in listFicheCollecte" :key="ficheCollecte.id" :label="ficheCollecte.id">
                    <el-tag v-if="ficheCollecte.code != ''" type="info" style="margin:10px">
                      Code : {{ ficheCollecte.code }}
                    </el-tag>
                  </el-tab-pane>
                </el-tabs> -->
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <!-- <el-table v-loading="loading" :data="mininfos" :show-header="false">
        <el-table-column>
          <template slot-scope="scope" style="background-color: bisque">
            <span style="font-weight: bold;">{{ scope.row.name1 }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.val1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <span style="font-weight: bold">{{ scope.row.name2 }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            {{ scope.row.val2 }}
          </template>
        </el-table-column>
      </el-table> -->
    <!-- <table v-if="data !== null" style="border : 1px solid #000000; width:100%" cellspacing="0">
        <tr>
          <td style="border: 1px solid #000;">Code UP</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
          <td style="border: 1px solid #000;">Nom du producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.nom + ' ' + data.prenom }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Zone</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Sexe (M/F)</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.sexe | uppercaseFirst }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Commune</td>
          <td style="border: 1px solid #000; width: 200px;">{{ }}</td>
          <td style="border: 1px solid #000;">Groupement</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomGroupements[data.groupement_id] }}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #000;">Village</td>
          <td style="border: 1px solid #000; width: 200px;">{{ nomVillages[data.village_id] }}</td>
          <td style="border: 1px solid #000;">Code Producteur</td>
          <td style="border: 1px solid #000; width: 200px;">{{ data.code }}</td>
        </tr>
      </table> -->

    <!-- </el-card> -->
    <!-- <h4>Liste des Parcelles du producteur</h4>
    <el-card class="box-card filter-card just-for-admin">
      <el-table :data="parcelles" border fit highlight-current-row style="width: 100%">
        <el-table-column align="center" label="Code" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.superficie')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.superficie }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('parcelle.nbr_arb')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ scope.row.nbr_arb }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="1==1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template>
            <el-button type="primary" size="small" icon="el-icon-view" style="margin-right:10px;" @click="navigateGroupement" />
          </template>
        </el-table-column>
      </el-table>
    </el-card> -->
  </div>
</template>

<script>
// import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const controleurResource = new Resource('controleurs');
const auditRessourcePdf = new Resource('controleurs/fiche/audit');
const auditRessource = new Resource('valider-audit');
const groupementResource = new Resource('groupements');
const villageResource = new Resource('villages');
const formationResource = new Resource('formations');
const controleurAudit = new Resource('parcelle-audits');

export default {
  name: 'ProducteurInfo',
  // components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      is_audit: 1,
      audits: [],
      listFicheCollecte: [],
      data: {},
      date: '',
      fullscreenLoading: true,
      parcelles: null,
      printing: false,
      list: null,
      total: 0,
      sexe: '',
      image: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      nomVillages: null,
      nomGroupements: null,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
      },
      loading: true,
      downloading: false,
      villages: null,
      groupements: null,
      activeList: 'disponibles',
      producteurCreating: false,
      editing: false,
      persinfos: null,
      organInfos: null,
      currentProducteur: {},
      activeName: '1',
      dialogCreateProducteur: false,
      currentProducteurId: 0,
      rules: {
        nom: [{ required: true, message: this.$t('producteur.NomRequired'), trigger: 'blur' }],
        prenom: [{ required: true, message: this.$t('producteur.PrenomRequired'), trigger: 'blur' }],
        numero: [{ required: true, message: this.$t('producteur.NumeroRequired'), trigger: 'blur' }],
        adresse: [{ required: true, message: this.$t('producteur.AdresseRequired'), trigger: 'blur' }],
      },
      fileList: [],
      mininfos: [],
    };
  },
  computed: {

  },
  created() {
    this.getGroupementsList();
    this.getVillagesList();
    this.getControleur();
    this.getFicheCollecteList();
  },
  methods: {
    checkPermission,
    checkRole,
    async generateAuditPdf(audi_id){
      var { data } = await auditRessourcePdf.getAuditPdf(this.$route.params.id, audi_id, this.$store.getters.camp_id);
      window.open(data, '_blank');
      console.log('PDF : ', data);
    },
    async validerAudit(audi_id){
      this.$confirm(this.$t('audit.confirmValidation'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        auditRessource.get(audi_id).then(response => {
          this.$message({
            type: 'success',
            message: response.message,
          });
          this.getControleur();
        }).catch(error => {
          this.$message({
            type: 'error',
            message: error.message,
          });
          console.log(error);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('audit.validationCancel'),
        });
      });
    },
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    upName(groupement_id) {
      console.log('groupement_id', groupement_id);
      this.groupements.forEach(groupement => {
        if (groupement.id === groupement_id) {
          console.log('groupement.unite_production.nom', groupement.unite_production.nom);
          return groupement.unite_production.nom;
        }
        console.log('humm');
        return 'humm';
      });
    },

    async getGroupementsList() {
      const { data } = await groupementResource.list(this.query);
      this.groupements = data;
      var _reg = {};
      var _unites_p = {};
      data.forEach(groupement => {
        _reg[groupement.id] = groupement.nom;
        _unites_p[groupement.unite_production_id] = groupement.unite_production.nom;
      });
      this.nomGroupements = _reg;
      this.unites_production = _unites_p;
      console.log('this.nomGroupements', this.nomGroupements);
      console.log('unites_production', this.unites_production);
    },
    PrintProductor(){
      var routeData = this.$router.resolve({ path: '/producteur/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateImpression(){
      var routeData = this.$router.resolve({ path: '/parcelle/' + this.$route.params.id });
      window.open(routeData.href, '_blank');
    },
    navigateGroupement(id){
      this.$router.push({ path: '/groupement/' + id });
    },
    navigateFormation(id){
      this.$router.push({ path: '/formations/' + id });
    },
    async getVillagesList() {
      const { data } = await villageResource.list(this.query);
      this.villages = data;
      var _reg = {};
      data.forEach(village => {
        _reg[village.id] = village.nom;
      });
      this.nomVillages = _reg;
    },
    async getFicheCollecteList() {
      const { data } = await controleurResource.sublist('/liste-fiches-collecte/' + this.$route.params.id, this.query);
      if (data.length > 0) {
        var fiches_data = [];
        data.forEach(fiche_element => {
          fiche_element.created_at = fiche_element.created_at ? moment(fiche_element.created_at).format('DD-MM-YYYY') : '';
          fiches_data.push(fiche_element);
        });
        this.listFicheCollecte = fiches_data;
      }
    },
    async getControleur() {
      // const { data } = await producteursResource.get(this.$route.params.id);
      var { data } = await controleurResource.get(this.$route.params.id);
      console.log('leproduitttttttttt : ', this.data);
      this.fullscreenLoading = false;
      console.log('le loading : ', this.fullscreenLoading);
      this.date = moment().format('DD-MM-YYYY');
      const conduct_data = {
        controleur_id: this.$route.params.id,
      };
      const followed_data = {
        controleur_id_participant: this.$route.params.id,
      };
      // var audit_element;
      var audits_data = [];
      var audits_id = this.$route.params.id;
      var followed_trainings = await formationResource.list(followed_data);
      var conduct_trainings = await formationResource.list(conduct_data);
      var audits = await controleurAudit.get(audits_id);

      data.conduct_trainings = conduct_trainings.data;
      data.followed_trainings = followed_trainings.data;
      data.audits = audits.data.audits;
      console.log('CONDUITS : ', conduct_trainings.data);
      console.log('FOLLOWED : ', followed_trainings.data);
      console.log('AUDITS: ', audits.data);
      const alls_audits = Object.values(audits.data[0]);
      if (alls_audits.length > 0) {
        alls_audits[0].forEach(audit_element => {
          audit_element.date_audit = audit_element.date_audit ? moment(audit_element.date_audit).format('DD-MM-YYYY') : '';
          audits_data.push(audit_element);
        });
      }
      /* if (audits.data.length > 0){
        for (let index = 0; index < Object.values(audits.data).length; index++) {
          audit_element = Object.values(audits.data)[index];
          console.log('audit : ', audit_element);
          for (let i = 0; i < Object.values(audit_element[0]).length; i++) {
            var individual_audit = Object.values(audit_element[0])[i];
            console.log('INDIVIDUAL AUDIT DATA : ', individual_audit[i]);
            audits_data.push(individual_audit[i]);
          }
        }
      } */
      if (audits_data.length === 0) {
        this.is_audit = 0;
        console.log('IS_AUDITS: ', this.is_audit);
      }
      console.log('audits_data: ', audits_data);
      this.audits = audits_data;

      this.data = data;
      // this.organInfos = [
      //   {
      //     'libelle': 'Code UP    :',
      //     'valeur': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Zone    :',
      //     'valeur': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Commune    :',
      //     'valeur': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Groupement',
      //     'valeur': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'libelle': 'Village',
      //     'valeur': data.village !== null ? data.village.nom : 'INDISPONIBLE',
      //   },
      // ];
      // this.persinfos = [
      //   {
      //     'libelle': 'Nom du producteur    :',
      //     'valeur': data.nom + ' ' + data.prenom,
      //   },
      //   {
      //     'libelle': 'Sexe (M/F)    :',
      //     'valeur': data.sexe,
      //   },
      // ];
      // this.mininfos = [
      //   {
      //     'name1': 'Code UP',
      //     'val1': data.unite_production !== null ? data.unite_production.nom : 'INDISPONIBLE',
      //     'name2': 'Nom du producteur',
      //     'val2': data.nom + ' ' + data.prenom,
      //   },
      //   {
      //     'name1': 'Zone',
      //     'val1': data.zone !== null ? data.zone.nom : 'INDISPONIBLE',
      //     'name2': 'Sexe (M/F)',
      //     'val2': data.sexe,
      //   },
      //   {
      //     'name1': 'Commune',
      //     'val1': data.commune !== null ? data.commune.nom : 'INDISPONIBLE',
      //     'name2': 'Groupement',
      //     'val2': data.groupement !== null ? data.groupement.nom : 'INDISPONIBLE',
      //   },
      //   {
      //     'name1': 'Village',
      //     'val1': data.village !== null ? data.village.nom : 'INDISPONIBLE',
      //     'name2': 'Code Producteur',
      //     'val2': data.code,
      //   },
      // ];
      this.loading = false;
      // console.log('tableau : ', this.mininfos);
      // if (data !== null) {
      //   window.print();
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}

.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.pan-thumb {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  border-radius: 75px;
  overflow: hidden;
  margin-bottom: 20px;
  // position: absolute;
  background-image:url('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg');
  //background-image:url('https://www.basketusa.com/wp-content/uploads/2022/08/Air-Jordan-37-Hare-DD6958-160-01.webp');
  // transform-origin: 95px 40px;
  // transition: all 0.3s ease-in-out;
  background-repeat:no-repeat;
}
</style>
